<template>
  <div class="website-banner" data-aos="fade-left">
    <h1 class="tvp-title">{{ $t("tvp.tvp00") }}</h1>
    <img src="@/assets/img/banner-2.png">
  </div>

  <div class="common-tb-120 common-padding" data-aos="fade-right" data-aos-delay="400">
    <div class="wechat-middle tvp-content" >
      <h1>{{ $t("tvp.tvp01") }}</h1>
      <div class="tvp-common-txt">
        <p><b>{{ $t("tvp.tvp02") }}</b></p>
        <p>{{ $t("tvp.tvp03") }}</p>
      </div>

      <div class="tvp-content-2">
          <div class="tvp-common-txt tvp-content-2-txt">
            <p><b>{{ $t("tvp.tvp04") }}</b></p>
            <p>{{ $t("tvp.tvp05") }}</p>
            <p>{{ $t("tvp.tvp06") }}</p>
            <p>{{ $t("tvp.tvp07") }}</p>
            <p>{{ $t("tvp.tvp08") }}</p>
            <ul>
              <li>{{ $t("tvp.tvp09") }}</li>
              <li>{{ $t("tvp.tvp10") }}</li>
              <li>{{ $t("tvp.tvp11") }}</li>
            </ul>
          </div>
          <div>
            <img :src="imgSrc">
          </div>
      </div>


        <div class="tvp-img">
          <img src="@/assets/img/tvp-4.jpg" >
        </div>


      <div class="widget-container">
       <p class="elementor-heading-title elementor-size-default">{{ $t("tvp.tvp34") }}</p>
      </div>

      <div class="elementor-widget-container table-two">
        <table>
          <tbody>
          <tr>
            <td>{{ $t("tvp.tvp35") }}</td>
            <td><p></p></td>
          </tr>
          <tr>
            <td>{{ $t("tvp.tvp36") }}</td>
            <td>
              <div class="long-d">
              <p>{{ $t("tvp.tvp37") }}</p>
              <p>{{ $t("tvp.tvp38") }}</p>
              <p>
                <a href="https://tvp.itf.gov.hk/zh-HK/Home/Index" target="_blank">https://tvp.itf.gov.hk/zh-HK/Home/Index</a>
              </p>
              <br/>
              <p>{{ $t("tvp.tvp39") }}</p>
              <p>
                <a href="https://www.itf.gov.hk/filemanager/sc/content_38/tvp-c_202008.pdf" target="_blank">
                  https://www.itf.gov.hk/filemanager/sc/content_38/tvp-c_202008.pdf
                </a>
              </p>
              <br/>
              <p>{{ $t("tvp.tvp40") }}</p>
              <p>
                <a href="https://www.itf.gov.hk/filemanager/publication/tc/upload/1215/TVP-guide-c_202006.pdf" target="_blank">
                https://www.itf.gov.hk/filemanager/publication/tc/upload/1215/TVP-guide-c_202006.pdf
                </a>
              </p>
              </div>
            </td>
          </tr>
          <tr>
            <td>{{ $t("tvp.tvp41") }}</td>
            <td>
              <p>{{ $t("tvp.tvp42") }}</p>
                <div class="tvp-content-2-txt">
                  <ul>
                    <li>{{ $t("tvp.tvp43") }}</li>
                    <li>{{ $t("tvp.tvp44") }}</li>
                    <li>{{ $t("tvp.tvp45") }}</li>
                  </ul>
                </div>
            </td>
          </tr>
          <tr>
            <td>{{ $t("tvp.tvp46") }}</td>
            <td><p></p></td>
          </tr>
          <tr>
            <td>{{ $t("tvp.tvp47") }}</td>
            <td>{{ $t("tvp.tvp470") }}</td>
          </tr>
          <tr>
            <td>{{ $t("tvp.tvp48") }}</td>
            <td></td>
          </tr>
          <tr>
            <td>{{ $t("tvp.tvp49") }}</td>
            <td></td>
          </tr>
          <tr>
            <td>{{ $t("tvp.tvp50") }}</td>
            <td></td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="tvp-common-txt">
        <p><b>{{ $t("tvp.tvp51") }}</b></p>
        <p>{{ $t("tvp.tvp52") }}</p>
        <p>{{ $t("tvp.tvp53") }}</p>
        <p>{{ $t("tvp.tvp54") }}</p>
        <p>{{ $t("tvp.tvp55") }}</p>
        <p>{{ $t("tvp.tvp56") }}</p>
      </div>

      <div class="tvp-common-txt">
        <p><b>{{ $t("tvp.tvp57") }}</b></p>
        <p>{{ $t("tvp.tvp58") }}</p>
        <p>{{ $t("tvp.tvp59") }}</p>
        <p>{{ $t("tvp.tvp60") }}</p>
      </div>

      <div class="tvp-common-txt">
        <p><b>{{ $t("tvp.tvp61") }}</b></p>
        <p>{{ $t("tvp.tvp62") }}</p>
        <p>{{ $t("tvp.tvp63") }}</p>
        <p>{{ $t("tvp.tvp64") }}
          <a href="https://tvp.itf.gov.hk/zh-HK/Home/Index" target="_blank">https://tvp.itf.gov.hk/zh-HK/Home/Index</a>
        </p>
        <p>{{ $t("tvp.tvp65") }}</p>
        <p>{{ $t("tvp.tvp66") }}</p>
        <p>{{ $t("tvp.tvp67") }}</p>
        <p>{{ $t("tvp.tvp68") }}</p>
      </div>

      <div class="tvp-img">
        <img src="@/assets/img/tvp-5.jpg" >
      </div>


      <div class="wt">
         <h1><span>{{ $t("tvp.tvp69") }}</span></h1>
         <div class="wt-li">
           <p><b>{{ $t("tvp.tvp111") }}  {{ $t("tvp.tvp70") }}</b></p>
           <p>{{ $t("tvp.tvp112") }} {{ $t("tvp.tvp71") }}</p>
         </div>
         <div class="wt-li">
           <p><b>{{ $t("tvp.tvp111") }}  {{ $t("tvp.tvp72") }}</b></p>
           <p>{{ $t("tvp.tvp112") }}  {{ $t("tvp.tvp73") }}
           <a href="https://tvp.itf.gov.hk/zh-HK/Home/Index" target="_blank">https://tvp.itf.gov.hk/zh-HK/Home/Index</a>
             {{ $t("tvp.tvp74") }}
           </p>
         </div>
         <div class="wt-li">
           <p><b>{{ $t("tvp.tvp111") }}  {{ $t("tvp.tvp75") }}</b></p>
           <p>{{ $t("tvp.tvp112") }}  {{ $t("tvp.tvp76") }}</p>
         </div>
         <div class="wt-li">
           <p><b>{{ $t("tvp.tvp111") }}  {{ $t("tvp.tvp77") }}</b></p>
           <p>{{ $t("tvp.tvp112") }}  {{ $t("tvp.tvp78") }}</p>
         </div>

        <div class="wt-li">
          <p><b>{{ $t("tvp.tvp111") }}  {{ $t("tvp.tvp79") }}</b></p>
          <p>{{ $t("tvp.tvp112") }}   {{ $t("tvp.tvp80") }}</p>
        </div>

        <div class="wt-li">
          <p><b>{{ $t("tvp.tvp111") }}  {{ $t("tvp.tvp81") }}</b></p>
          <p>{{ $t("tvp.tvp112") }}  {{ $t("tvp.tvp82") }}</p>
        </div>

        <div class="wt-li">
          <p><b>{{ $t("tvp.tvp111") }}  {{ $t("tvp.tvp83") }}</b></p>
          <p>{{ $t("tvp.tvp112") }}  {{ $t("tvp.tvp84") }}</p>
        </div>


        <div class="wt-li">
          <p><b>{{ $t("tvp.tvp111") }}  {{ $t("tvp.tvp87") }}</b></p>
          <p>{{ $t("tvp.tvp112") }} </p>
          <div class="elementor-widget-container">
            <table>
              <tbody>
              <tr>
                <td style="background-image: linear-gradient(250deg, #23B0BF 0%, #23B0BF 100%); color: #fff">
                  <p style=" color: #fff;font-size: 18px;font-weight: bold">{{ $t("tvp.tvp88") }}</p>
                </td>
                <td style="background-image: linear-gradient(250deg, #23B0BF 0%, #23B0BF 100%);">
                  <p style=" color: #fff;font-size: 18px;font-weight: bold">{{ $t("tvp.tvp89") }}</p>
                </td>
              </tr>
              <tr>
                <td>{{ $t("tvp.tvp90") }}</td>
                <td><p>{{ $t("tvp.tvp91") }}</p></td>
              </tr>
              <tr>
                <td>{{ $t("tvp.tvp92") }}</td>
                <td><p>{{ $t("tvp.tvp93") }}</p>
                </td>
              </tr>
              <tr>
                <td>{{ $t("tvp.tvp94") }}</td>
                <td><p>{{ $t("tvp.tvp95") }}</p></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="wt-li">
          <p><b>{{ $t("tvp.tvp111") }}  {{ $t("tvp.tvp96") }}</b></p>
          <p>{{ $t("tvp.tvp112") }}   {{ $t("tvp.tvp97") }}</p>
        </div>



        <div class="wt-li">
          <p><b>{{ $t("tvp.tvp111") }}  {{ $t("tvp.tvp98") }}</b></p>
          <p>{{ $t("tvp.tvp112") }}  {{ $t("tvp.tvp99") }}</p>
          <div class="tvp-common-txt">
            <li>{{ $t("tvp.tvp100") }}</li>
            <li>{{ $t("tvp.tvp101") }}</li>
            <li>{{ $t("tvp.tvp102") }}</li>
            <li>{{ $t("tvp.tvp103") }}</li>
            <li>{{ $t("tvp.tvp104") }}</li>
            <li>{{ $t("tvp.tvp105") }}</li>
            <li>{{ $t("tvp.tvp106") }}</li>
            <li>{{ $t("tvp.tvp107") }}</li>
          </div>
        </div>

        <div class="wt-li">
          <p><b>{{ $t("tvp.tvp111") }}  {{ $t("tvp.tvp108") }}</b></p>
          <p>{{ $t("tvp.tvp112") }}  {{ $t("tvp.tvp109") }}</p>
        </div>

       </div>
    </div>
  </div>

  <div class="page-banner common-tb-120"  style="background: #FAFAFA;">
    <div class="page-banner-content">
      <h1 data-aos="zoom-in"></h1>
      <div class="quote-button-bottom">
        <div data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400">
          <a href="javascript:;" class="btn btn2" @click="openPopup()">
             <span class="btn-inner">
              {{ $t("tvp.tvp110") }}<img src="@/assets/img/btn_pic01.png">
             </span>
            <div class="btnbg-x"></div>
          </a>
        </div>
        <div class="finger" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="800">
          <img src="@/assets/img/finger.png">
        </div>
      </div>
    </div>
  </div>


<!-- 彈窗 -->
  <div class="tvp-pop" v-show="visible">
      <div class="tvp-pop-content">
          <div class="tvp-pop-close">
            <p @click="hidePop()">X</p>
          </div>
          <requestForm></requestForm>
      </div>
  </div>

</template>

<script>

import requestForm from "@/components/requestForm";

export default {
  name: "TVP",
  components:{
    requestForm
  },
  data(){
    return{
      visible: false,
      imgSrc: this.$t('tvp.url'),
    }
  },
  methods:{
    openPopup(){
      this.visible = true
    },
    hidePop() {
      this.visible = false
    },

  }
}
</script>

<style scoped>

.tvp-pop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.tvp-pop-content{
  width: 800px;
  padding: 30px;
  background: #fff;
  position: relative;
}

.tvp-pop-close{
  font-size: 24px;
  position: absolute;
  top: 15px;
  right: 15px;
  color: #000;
  cursor: pointer;
}

.quote-button-bottom{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.quote-button-bottom a{
  padding: 15px 40px;
  color: #000;
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 50px;
  margin-top: 30px;
  font-size: 20px;
}

.quote-button-bottom a img{
  width: 32px;
  padding-left: 10px;
}

.finger{
  position:absolute;
  top: 40px;
  right: -70px;
}

.finger img{
  width: 100px;
}

.finger{
  cursor: pointer;
  animation: fingerHandle 1s ease infinite both;
}


@keyframes fingerHandle {
  0% {
    transform: none;
  }
  70% {
    transform: scale3d(.8, .8, .8);
  }
  100% {
    transform: none;
  }
}


.btn2 {
  border: 1px solid #ddd;
  position: relative;
  background: #fff;
  color: #000;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  box-shadow: 0px 2px 6px rgba(121, 121, 121, 0.25);
}

.btn2:hover {
  color: #fff;
}

.btn2 .btnbg-x {
  position: absolute;
  left: -1px;
  width: 0;
  top: -1px;
  bottom: -1px;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.btn2:hover .btnbg-x {
  width: 100%;
  width: calc(100% + 2px);
  background: var(--background);
  border-radius: 50px;
}

.btn2 .btn-inner {
  padding: 0 12px;
  position: relative;
  z-index: 1;
}

</style>